import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Header from "../components/header"
import Image from "../components/image"

import "./layout.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <>
        <SEO title="2018 iF3 Worlds – 2018 International Functional Fitness World Championships in London, England"
           keywords={[`if3`, `international`, `functional`, `fitness`, `federation`,
               `international functional fitness federation`, `crossfit`, `worlds`, `london`, `england`, `sport`]} />
        <div id="container">
          <Header/>
          <main>
            <div className="image-container">
              <Image />
            </div>

            {children}
          </main>
        </div>
        <footer>
          © <a href="https://functionalfitness.sport" target="_blank" rel="noopener noreferrer">International Functional Fitness Federation</a>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
