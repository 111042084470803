import React from "react"
import { Link } from "gatsby"

import "./header.scss"

const Header = () => (
  <header>
    <ul>
      <li>
        <Link activeClassName="active" to="/">Home</Link>
      </li>
      <li>
        <Link activeClassName="active" to="/participants/">Participating Countries</Link>
      </li>
      <li>
        <a title="iF3" target="_blank" rel="noopener noreferrer" href="https://functionalfitnessfederation.org">iF3</a>
      </li>
    </ul>
  </header>
)

export default Header
